import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import * as CameronGrandTeton from '../assets/cameron-grand-teton-2.jpeg';
import * as CraterLake from '../assets/crater-lake.jpeg';
import * as CraterLakeVideo from '../assets/crater-lake.mp4';
import * as CraterLakeVideo2 from '../assets/crater-lake-2.mp4';
import * as WyomingDrive from '../assets/wyoming-drive.jpeg';
import * as DiamondLake from '../assets/diamond-lake.jpeg';
import * as OregonVideo from '../assets/oregon.mp4';
import * as GrandCanyon from '../assets/grand-canyon.jpeg';
import * as GrandTeton from '../assets/grand-teton.jpeg';
import * as GrandTeton2 from '../assets/grand-teton-2.jpeg';
import * as KingsCanyonVideo from '../assets/kings-canyon.mp4';
import * as KingsCanyonVideo2 from '../assets/kings-canyon-2.mp4';
import * as MurphDenver from '../assets/murph-denver.jpeg';
import * as MurphDiamondLake from '../assets/murph-diamond-lake.jpeg';
import * as MurphMalibu from '../assets/murph-malibu.jpeg';
import * as MalibuVideo from '../assets/malibu.mp4';
import * as MurphRedrocks from '../assets/murph-redrocks.jpeg';
import * as Redrocks from '../assets/redrocks.jpeg';
import * as LakeTahoeVideo from '../assets/lake-tahoe.mp4';
import * as MurphSacramento from '../assets/murph-sacramento.jpeg';
import * as MurphSanDiego from '../assets/murph-san-diego.jpeg';
import * as MurphSanDiego2 from '../assets/murph-san-diego-2.jpeg';
import * as SanDiegoVideo from '../assets/san-diego.mp4';
import * as MurphSanFrancisco from '../assets/murph-san-francisco.jpeg';
import * as MurphStLouis from '../assets/murph-st-louis.jpeg';
import * as UtahVideo from '../assets/utah.mp4';
import * as MurphUtk from '../assets/murph-utk.jpeg';
import * as Yellowstone from '../assets/yellowstone.jpeg';
import * as CraterLakeDrive from '../assets/crater-lake-drive.jpeg';
import * as AcadiaLake from '../assets/acadia-lake.jpg';
import * as AcadiaLake2 from '../assets/acadia-lake-2.jpg';
import * as AcadiaMorning from '../assets/acadia-morning.jpg';
import * as AcadiaRocks from '../assets/acadia-rocks.jpg';
import * as AcadiaVideo from '../assets/acadia-video.mp4';
import * as EchoLake from '../assets/echo-lake.jpg';
import * as LakeChamplainMurph from '../assets/lake-champlain-murph.jpg';
import * as LakeChamplainProperty from '../assets/lake-champlain-property.jpg';
import * as MontrealSkyline from '../assets/montreal-skyline.jpg';
import * as NiagaraFalls from '../assets/niagara-falls.jpg';
import * as Ottawa from '../assets/ottawa.jpg';
import * as Ottawa2 from '../assets/ottawa-2.jpg';
import * as Toronto from '../assets/toronto.jpg';
import * as TorontoStadium from '../assets/toronto-stadium.jpg';
import { PhotoModal } from './parts/PhotoModal';

export class Photos extends Component {
    static displayName = Photos.name;
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0,
            items: [
                {
                    src: GrandTeton,
                    altText: 'Grand Teton National Park, Wyoming',
                    header: 'Grand Teton National Park',
                    caption: 'Wyoming'
                },
                {
                    src: GrandTeton2,
                    altText: 'Grand Teton National Park, Wyoming',
                    header: 'Grand Teton National Park',
                    caption: 'Wyoming'
                },
                {
                    src: CameronGrandTeton,
                    altText: 'Grand Teton National Park, Wyoming',
                    header: 'Grand Teton National Park',
                    caption: 'Wyoming'
                },
                {
                    src: WyomingDrive,
                    altText: 'Wyoming',
                    header: 'Wyoming',
                },
                {
                    src: Yellowstone,
                    altText: 'Yellowstone National Park, Montana',
                    header: 'Yellowstone National Park',
                    caption: 'Montana'
                },
                {
                    src: CraterLakeDrive,
                    altText: 'Crater Lake, Oregon',
                    header: 'Crater Lake',
                    caption: 'Oregon'
                },
                {
                    src: CraterLake,
                    altText: 'Crater Lake, Oregon',
                    header: 'Crater Lake',
                    caption: 'Oregon',
                },
                {
                    video: true,
                    src: CraterLakeVideo,
                    altText: 'Crater Lake, Oregon',
                    header: 'Crater Lake',
                    caption: 'Oregon',
                },
                {
                    video: true,
                    src: CraterLakeVideo2,
                    altText: 'Crater Lake, Oregon',
                    header: 'Crater Lake',
                    caption: 'Oregon',
                },
                {
                    src: DiamondLake,
                    altText: 'Diamond Lake, Oregon',
                    header: 'Diamond Lake',
                    caption: 'Oregon'
                },
                {
                    video: true,
                    src: OregonVideo,
                    altText: 'Oregon',
                    header: 'Oregon',
                },
                {
                    src: MurphDiamondLake,
                    altText: 'Diamond Lake, Oregon',
                    header: 'Diamond Lake',
                    caption: 'Oregon'
                },
                {
                    src: GrandCanyon,
                    altText: 'Grand Canyon, Arizona',
                    header: 'Grand Canyon',
                    caption: 'Arizona'
                },
                {
                    video: true,
                    src: LakeTahoeVideo,
                    altText: 'Lake Tahoe, California',
                    header: 'Lake Tahoe',
                    caption: 'California'
                },
                {
                    video: true,
                    src: KingsCanyonVideo,
                    altText: 'Kings Canyon National Park, California',
                    header: 'Kings Canyon National Park',
                    caption: 'California'
                },
                {
                    video: true,
                    src: KingsCanyonVideo2,
                    altText: 'Kings Canyon National Park, California',
                    header: 'Kings Canyon National Park',
                    caption: 'California'
                },
                {
                    src: AcadiaLake,
                    altText: 'Acadia National Park, Maine',
                    header: 'Acadia National Park',
                    caption: 'Maine'
                },
                {
                    src: AcadiaLake2,
                    altText: 'Acadia National Park, Maine',
                    header: 'Acadia National Park',
                    caption: 'Maine'
                },
                {
                    src: AcadiaMorning,
                    altText: 'Acadia National Park, Maine',
                    header: 'Acadia National Park',
                    caption: 'Maine'
                },
                {
                    src: AcadiaRocks,
                    altText: 'Acadia National Park, Maine',
                    header: 'Acadia National Park',
                    caption: 'Maine'
                },
                {
                    video: true,
                    src: AcadiaVideo,
                    altText: 'Acadia National Park, Maine',
                    header: 'Acadia National Park',
                    caption: 'Maine'
                },
                {
                    src: EchoLake,
                    altText: 'Echo Lake, New Hampshire',
                    header: 'Echo Lake',
                    caption: 'New Hampshire'
                },
                {
                    src: LakeChamplainMurph,
                    altText: 'Lake Champlain, Vermont',
                    header: 'Lake Champlain',
                    caption: 'Vermont'
                },
                {
                    src: LakeChamplainProperty,
                    altText: 'Lake Champlain, Vermont',
                    header: 'Lake Champlain',
                    caption: 'Vermont'
                },
                {
                    src: MontrealSkyline,
                    altText: 'Montreal, Quebec',
                    header: 'Montreal',
                    caption: 'Quebec'
                },
                {
                    src: NiagaraFalls,
                    altText: 'Niagara Falls, Ontario',
                    header: 'Niagara Falls',
                    caption: 'Ontario'
                },
                {
                    src: Ottawa,
                    altText: 'Ottawa, Ontario',
                    header: 'Ottawa',
                    caption: 'Ontario'
                },
                {
                    src: Ottawa2,
                    altText: 'Ottawa, Ontario',
                    header: 'Ottawa',
                    caption: 'Ontario'
                },
                {
                    src: Toronto,
                    altText: 'Toronto, Ontario',
                    header: 'Toronto',
                    caption: 'Ontario'
                },
                {
                    src: TorontoStadium,
                    altText: 'Ottawa, Ontario',
                    header: 'Toronto',
                    caption: 'Ontario'
                },
                {
                    src: MurphDenver,
                    altText: 'Denver, Colorado',
                    header: 'Denver',
                    caption: 'Colorado'
                },
                {
                    src: MurphRedrocks,
                    altText: 'Red Rocks Park & Ampitheatre, Colorado',
                    header: 'Red Rocks Park & Ampitheatre',
                    caption: 'Colorado'
                },
                {
                    src: Redrocks,
                    altText: 'Red Rocks Park & Ampitheatre, Colorado',
                    header: 'Red Rocks Park & Ampitheatre',
                    caption: 'Colorado'
                },
                {
                    src: MurphMalibu,
                    altText: 'Malibu, California',
                    header: 'Malibu',
                    caption: 'California'
                },
                {
                    video: true,
                    src: MalibuVideo,
                    altText: 'Malibu, California',
                    header: 'Malibu',
                    caption: 'California'
                },
                {
                    src: MurphSacramento,
                    altText: 'Sacramento, California',
                    header: 'Sacramento',
                    caption: 'California'
                },
                {
                    src: MurphSanDiego,
                    altText: 'San Diego, California',
                    header: 'San Diego',
                    caption: 'California'
                },
                {
                    src: MurphSanDiego2,
                    altText: 'San Diego, California',
                    header: 'San Diego',
                    caption: 'California'
                },
                {
                    video: true,
                    src: SanDiegoVideo,
                    altText: 'San Diego, California',
                    header: 'San Diego',
                    caption: 'California'
                },
                {
                    src: MurphSanFrancisco,
                    altText: 'San Francisco, California',
                    header: 'San Francisco',
                    caption: 'California'
                },
                {
                    src: MurphStLouis,
                    altText: 'St. Louis, Missouri',
                    header: 'St. Louis',
                    caption: 'Missouri'
                },
                {
                    video: true,
                    src: UtahVideo,
                    altText: 'Utah',
                    header: 'Utah'
                },
                {
                    src: MurphUtk,
                    altText: 'University of Tennessee - Knoxville',
                    header: 'University of Tennessee - Knoxville'
                },
            ],
            photoModal: false
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.pauseVideos = this.pauseVideos.bind(this);
        this.togglePhotoModal = this.togglePhotoModal.bind(this);
        this.handleLoad = this.handleLoad.bind(this);

        this.videoRefs = [];
    }

    componentDidMount() {
        const numVideos = [...this.state.items].filter(it => it.video).length
        for (let i = 0; i < numVideos; i++) {
            this.videoRefs.push(React.createRef())
        }
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
        this.pauseVideos();
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
        this.pauseVideos();
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
        this.pauseVideos();
    }

    pauseVideos() {
        this.videoRefs.filter(it => it.current).forEach(ref => {
            ref.current.pause();
        })
    }

    togglePhotoModal() {
        this.setState({ photoModal: !this.state.photoModal })
    }

    handleLoad(item) {
        let items = [...this.state.items]
        let officialItem = items.find(it => it.src === item.src);
        officialItem.loaded = true
        this.setState({ items })
    }

    render() {
        const { activeIndex, items } = this.state;

        const slides = items.map((item) => {
            const videoIdx = item.video ? items.filter(it => it.video).indexOf(item) : null;
            return (
                <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item.src}
                >
                    <div className="carousel-item-container">
                        {item.video ?
                            <video playsInline autoPlay muted src={item.src} alt={item.altText} onLoad={this.props.loadedImage} controls ref={this.videoRefs[videoIdx] || null} />
                            :
                            <img src={item.src} alt={item.altText} onLoad={this.props.loadedImage} onClick={this.togglePhotoModal} className="ignore-load" />
                        }
                        <CarouselCaption captionText={item.caption || ''} captionHeader={item.header || ''} />
                    </div>
                </CarouselItem>
            );
        });

        return (
            <div id="photos" className="page photos-page d-none section-grouping">
                <PhotoModal open={this.state.photoModal} items={items} handleLoad={this.handleLoad} activeIndex={activeIndex} next={this.next} previous={this.previous} goToIndex={this.goToIndex} onExiting={this.onExiting} onExited={this.onExited} togglePhotoModal={this.togglePhotoModal} />
                <div className="photos-wrapper fade" />
                <br />
                <br />
                <div style={{ width: '80%', margin: 'auto' }}>
                    <div>
                        <div className="page-header-wrapper fade">
                            <div className="page-header">
                                <h4><span>Photos</span></h4>
                            </div>
                        </div>
                        <div className="fade">
                            <button className="btn btn-sm btn-info" onClick={this.togglePhotoModal}>View Full Screen</button>
                            <br />
                            <br />
                            <Carousel
                                activeIndex={activeIndex}
                                next={this.next}
                                previous={this.previous}
                                interval={false}
                            >
                                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                {slides}
                                <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                            </Carousel>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <br />
            </div>
        );
    }
}
