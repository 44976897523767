import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

export class PhotoModal extends Component {
    static displayName = PhotoModal.name;

    constructor(props) {
        super(props)
        this.state = {
        }

        this.handleLoad = this.handleLoad.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);

        this.videoRefs = [];
    }

    componentDidMount() {
        const numVideos = this.props.items.filter(it => it.video).length
        for (let i = 0; i < numVideos; i++) {
            this.videoRefs.push(React.createRef())
        }
    }

    next() {
        this.props.next();
        this.pauseVideos();
    }

    previous() {
        this.props.previous();
        this.pauseVideos();
    }

    goToIndex() {
        this.props.goToIndex();
        this.pauseVideos();
    }

    pauseVideos() {
        this.videoRefs.filter(it => it.current).forEach(ref => {
            ref.current.pause();
        })
    }

    handleLoad(item) {
        this.props.handleLoad(item)
    }

    render() {
        const { items, activeIndex, open } = this.props;

        const slides = items.map((item) => {
            const videoIdx = item.video ? items.filter(it => it.video).indexOf(item) : null;
            return (
                <CarouselItem
                    onExiting={this.props.onExiting}
                    onExited={this.props.onExited}
                    key={item.src}
                >
                    <div className="carousel-item-container">
                        {item.video ?
                            <video playsInline autoPlay muted src={item.src} alt={item.altText} controls ref={this.videoRefs[videoIdx] || null} />
                            :
                            <>
                                {!item.loaded &&
                                    <div className="d-flex justify-content-center center-spinner">
                                        <div className="spinner-border text-info" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                <img src={item.src} alt={item.altText} onLoad={() => this.handleLoad(item)} className={item.loaded ? '' : 'hidden'} />
                            </>
                        }
                        <CarouselCaption captionText={item.caption || ''} captionHeader={item.header || ''} />
                    </div>
                </CarouselItem>
            );
        });

        return (
            <Modal isOpen={open} centered toggle={() => this.props.togglePhotoModal} className="full-screen">
                <ModalHeader toggle={this.props.togglePhotoModal} style={{ borderBottom: 'none' }} />
                <ModalBody>
                    <Carousel
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                        interval={false}
                    >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                    </Carousel>
                </ModalBody>
            </Modal>
        )
    }
}
