import React, { Component } from 'react';
import $ from 'jquery';

export class About extends Component {
    static displayName = About.name;

    constructor(props) {
        super(props)
        this.state = {
            sectionExpanded: {
                personalWork: true,
                hobbies: false,
                college: false,
                work: false
            }
        }

        this.handleInPageLink = this.handleInPageLink.bind(this);
        this.toggleSection = this.toggleSection.bind(this);
    }

    handleInPageLink(id) {
        const el = document.getElementById(id)
        const pageTop = $(document).scrollTop();
        const pageBottom = pageTop + $(window).height();

        const scrollTo = $(el).offset().top
        const scrollTime = scrollTo - pageBottom > 3000 ? 1500 : 800
        $('html, body').animate({
            scrollTop: scrollTo
        }, scrollTime)
    }

    toggleSection(section) {
        let sections = this.state.sectionExpanded;
        sections[section] = !sections[section];

        this.setState({ sections }, () => {
            window.scrollBy(0, 1); //make the scroll effects occur
        })
    }

    render() {
        return (
            <div id="about" className="page about-page d-none">
                <div className="about-wrapper fade" />
                <br />
                <br />
                <div style={{ width: '80%', margin: 'auto' }}>
                    <div>
                        <div>
                            <div className="page-header-wrapper fade">
                                <div className="page-header">
                                    <h4><span>About Me</span></h4>
                                </div>
                            </div>
                        </div>
                        <div className="section-box full fade">
                            <div className="section-header">
                                <h5>Personal Work</h5>
                                <button className="btn section-toggle" onClick={() => this.toggleSection('personalWork')}>{this.state.sectionExpanded['personalWork'] ? '-' : '+'}</button>
                            </div>
                            <div style={{ display: this.state.sectionExpanded['personalWork'] ? 'block' : 'none' }}>
                                <div className="section-box-body">
                                    <p>I will cover the occupational side of my programming in the <a href="#" className="link-primary" onClick={() => this.handleInPageLink("resume")}>Résumé</a> section, but I also do a good bit
                                        of software development purely for fun, as well:</p>
                                    <ul className="full-list">
                                        <li><span className="app">Baltimordle</span>
                                            <ul>
                                                <li>The most popular web application and game I've built, played by users across more than 20 countries and 40 U.S. states in just its first 24 hours.</li>
                                                <li>Users have 6 attempts to guess a mystery Baltimore Ravens player, with a new player being chosen every day.</li>
                                                <li>Due to the high amount of usage, I transitioned Baltimordle into an iOS app.</li>
                                            </ul>
                                        </li>
                                        <li><a className="app" target="_blank" rel="noopener noreferrer" href="https://sportlegames.com">SportleGames.com</a>
                                            <ul>
                                                <li>With the success of Baltimordle came requests for the same game to be built for other NFL teams as well as other sports.</li>
                                                <li>Sportle implements the same idea as Baltimordle but for every team in the following leagues: NFL, NBA, WNBA, NHL, MLB, MLS, NWSL, Premier League, and La Liga.</li>
                                                <li>There is also an "All Sports" section that challenges users who believe they are knowledgeable fans of multiple sports, as this game chooses a new mystery player daily from ANY of the aforementioned leagues!</li>
                                                <li>In March 2024, <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936">iOS</a> & <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">Android</a> app versions of Sportle were also created (which replaced Baltimordle).</li>
                                            </ul>
                                        </li>
                                        <li><a className="app" target="_blank" rel="noopener noreferrer" href="https://droidle.app">Droidle.app</a><ul><li>Users compete against a bot in a series of challenging word games at a user-chosen level of difficulty.</li></ul></li>
                                        <li><a className="app" target="_blank" rel="noopener noreferrer" href="https://recommend-me.io">Recommend-Me.io</a>
                                            <ul>
                                                <li>The very first web application I built.</li>
                                                <li>A free service that provides recommendations for music, tv shows, movies, and podcasts. Entering a variety of inputs, such as mood, favorite music artists, favorite actors & actresses, etc., this application provides a quick and easy way to get helpful recommendations.</li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <div className="icons">
                                        <div>
                                            <figure className="one-one">
                                                <img className="no-border-radius" src={require('../assets/sportle-iOS.png')} style={{ borderRadius: '25px' }} alt="Sportle iOS icon" onLoad={this.props.loadedImage} />
                                                <br /><a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936"><figcaption className="link">Sportle (iOS)</figcaption></a>
                                                <br /><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle"><figcaption className="link">Sportle (Android)</figcaption></a>
                                                <br /><a target="_blank" rel="noopener noreferrer" href="https://sportlegames.com"><figcaption className="link">SportleGames.com</figcaption></a>
                                            </figure>
                                        </div>
                                        <div>
                                            <a target="_blank" rel="noopener noreferrer" href="https://droidle.app">
                                                <figure className="one-one">
                                                    <img className="no-border-radius" src={require('../assets/droidle.ico')} alt="Droidle icon" onLoad={this.props.loadedImage} />
                                                    <br /><figcaption className="link">Droidle.app</figcaption>
                                                </figure>
                                            </a>
                                        </div>
                                        <div>
                                            <a target="_blank" rel="noopener noreferrer" href="https://recommend-me.io">
                                                <figure className="one-one">
                                                    <img className="no-border-radius" src={require('../assets/recommend-me.ico')} alt="Recommend Me icon" onLoad={this.props.loadedImage} />
                                                    <br /><figcaption className="link">Recommend-Me.io</figcaption>
                                                </figure>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-box full fade">
                            <div className="section-header">
                                <h5>Hobbies/Passions</h5>
                                <button className="btn section-toggle" onClick={() => this.toggleSection('hobbies')}>{this.state.sectionExpanded['hobbies'] ? '-' : '+'}</button>
                            </div>
                            <div style={{ display: this.state.sectionExpanded['hobbies'] ? 'block' : 'none' }}>
                                <div className="section-box-body">
                                    <p>
                                        <span className="hobby-name">Nature/Travel</span>: I've always been fascinated by "the great outdoors" and seeing what the massive, broad world has to offer, but I didn't do a ton of exploring
                                        until I reached college. It was only then that I started to pursue my passion for hiking, international travel, etc. Once I began, though, I couldn't get enough — since graduating, I have continued to pursue this passion, most notably by traveling
                                        around the United States in 2021 by car with my dog Murph, a Border Collie rescue from the Bahamas. I then traveled with Murph again in 2022 through the Northeastern United States and Canada. For more photos & videos of these journies, please see the <a href="#" className="link-primary" onClick={() => this.handleInPageLink("photos")}>Photos</a> section.
                                    </p>
                                    <figure className="three-three mobile-hide">
                                        <img src={require('../assets/matthews-park-colorado.jpeg')} alt="Colorado" onLoad={this.props.loadedImage} />
                                        <figcaption>Colorado</figcaption>
                                    </figure>
                                    <figure className="three-three">
                                        <img src={require('../assets/lake-tahoe.jpeg')} alt="Lake Tahoe, California" onLoad={this.props.loadedImage} />
                                        <figcaption>Lake Tahoe, California</figcaption>
                                    </figure>
                                    <figure className="three-three mobile-hide">
                                        <img src={require('../assets/cameron-diamond-lake.jpeg')} alt="Diamond Lake, Oregon" onLoad={this.props.loadedImage} />
                                        <figcaption>Diamond Lake, Oregon</figcaption>
                                    </figure>
                                    <figure className="three-three" >
                                        <img src={require('../assets/cameron-grand-teton-1.jpeg')} alt="Grand Teton, Wyoming" onLoad={this.props.loadedImage} />
                                        <figcaption>Grand Teton, Wyoming</figcaption>
                                    </figure>
                                    <hr />
                                    <div className="book-genres-container">
                                        <span className="hobby-name">Reading</span>: An easy way for me to wind down and relax is to pick up a book. Typical genres for me include:<br />
                                        <div className="book-genres">
                                            <div className="book-genre">
                                                <span className="genre-header">neuroscience/psychology</span>
                                                <ul>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/25776132-the-brain">The Brain: The Story of You</a></em></li>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/en/book/show/9827912-incognito">Incognito: The Secret Lives of the Brain</a></em></li>
                                                </ul>
                                            </div>
                                            <div className="book-genre">
                                                <span className="genre-header">philosophy</span>
                                                <ul>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/30659.Meditations">Meditations</a></em></li>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/4069.Man_s_Search_for_Meaning">Man's Search for Meaning</a></em></li>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/6596.The_Four_Agreements">The Four Agreements</a></em></li>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/6708.The_Power_of_Now">The Power of Now</a></em></li>
                                                    <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/2547.The_Prophet">The Prophet</a></em></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            Though not my favorite genre, fiction also has a special way of allowing oneself to 'dissociate' from the world for a short time. Some of my favorite fiction novels are&nbsp;
                                            <em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/18144590-the-alchemist">The Alchemist</a></em>,&nbsp;
                                            <em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/3636.The_Giver">The Giver</a></em>, and&nbsp;
                                            <em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/43708884-the-boy-the-mole-the-fox-and-the-horse">The Boy, the Mole, the Fox, and the Horse</a></em>
                                            &nbsp;(yes, that last one is a children's book, and it's incredible). One last favorite that crosses through numerous genres is&nbsp;
                                            <em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/28257707-the-subtle-art-of-not-giving-a-f-ck">The Subtle Art of Not Giving a F*ck</a></em>.
                                        </div>
                                    </div>
                                    <br />
                                    <figure className="three-onefive">
                                        <img src={require('../assets/the_brain.jpg')} alt="The Brain" className="no-border-radius" onLoad={this.props.loadedImage} />
                                        <figcaption><em>The Brain: The Story of You</em></figcaption>
                                    </figure>
                                    <figure className="three-onefive">
                                        <img src={require('../assets/the_giver.jpg')} alt="The Giver" className="no-border-radius" onLoad={this.props.loadedImage} />
                                        <figcaption><em>The Giver</em></figcaption>
                                    </figure>
                                    <hr />
                                    <p>
                                        <span className="hobby-name">Sports</span>: A highly active kid, I began playing soccer at around six years old and still do so today. I am also a volunteer assistant with the Mills Godwin High School Boys Soccer program.
                                        Additionally, I've played basketball recreationally for 10+ years, and have been weightlifting since I was a junior in high school (2013).
                                        As far as professional sports go, I'm a massive Baltimore Ravens fan, and also support the Washington Wizards when it comes to the NBA.
                                    </p>
                                    <figure className="three-threefive">
                                        <img src={require('../assets/states.jpeg')} alt="Virginia 5A State Champions (2015)" onLoad={this.props.loadedImage} />
                                        <figcaption>Virginia 5A State Champions (2015)</figcaption>
                                    </figure>
                                    <hr />
                                    <p>
                                        <span className="hobby-name">Animals</span>: Not that it wasn't already obvious given all the pictures of my dog, but I am indeed an animal enthusiast. Aside from growing up with bunnies, hamsters, guinea pigs, birds, and more,
                                        I also spent a brief period of time before COVID-19 volunteering at a local nonprofit, no-kill humane society.
                                    </p>
                                    <figure className="three-three">
                                        <img src={require('../assets/cameron-murph.jpeg')} alt="Murphy" onLoad={this.props.loadedImage} />
                                        <br /><figcaption>Murphy</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="section-box full fade">
                            <div className="section-header">
                                <h5>College</h5><br />
                                <span className="time-span">(2015-2019)</span>
                                <button className="btn section-toggle" onClick={() => this.toggleSection('college')}>{this.state.sectionExpanded['college'] ? '-' : '+'}</button>
                            </div>
                            <div style={{ display: this.state.sectionExpanded['college'] ? 'block' : 'none' }}>
                                <div className="section-box-body">
                                    <p>I accomplished a lifelong dream in attending the Virginia Military Institute in 2015, eventually graduating in the top 25 of my class in 2019. While attending,
                                        I held numerous extracurricular leadership positions as well as served as a peer tutor.</p>
                                    <figure className="two-two">
                                        <img src={require('../assets/rats.JPG')} alt="Virginia Military Institute" onLoad={this.props.loadedImage} className="no-border-radius" />
                                        <figcaption>Virginia Military Institute</figcaption>
                                    </figure>
                                    <figure className="two-two mobile-hide">
                                        <img src={require('../assets/ring-figure.jpeg')} alt="Virginia Military Institute Ring Figure" onLoad={this.props.loadedImage} />
                                        <figcaption>Virginia Military Institute Ring Figure</figcaption>
                                    </figure>
                                    <figure className="three-two">
                                        <img src={require('../assets/cameron-vmi.jpeg')} alt="Senior Year VMI" onLoad={this.props.loadedImage} />
                                        <figcaption>Senior Year</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <div className="section-box full fade">
                            <div className="section-header">
                                <h5>Post-Graduation Work</h5><br />
                                <small className="text-muted">*More in-depth details in the next section*</small>
                                <button className="btn section-toggle" onClick={() => this.toggleSection('work')}>{this.state.sectionExpanded['work'] ? '-' : '+'}</button>
                            </div>
                            <div style={{ display: this.state.sectionExpanded['work'] ? 'block' : 'none' }}>
                                <div className="section-box-body">
                                    <p>Upon graduation, I began working at Core Consulting in Richmond, Virginia as a business analyst. My role quickly evolved on my first project with a non-profit insurance company, as I took on
                                        the role of Automated Test Developer after just a few months, despite having zero programming background. I've always loved challenges, learning, and anything technical in nature, so I rapidly
                                        found passion for coding. After an additional 6 months (nearly a year total since joining the Core Consulting team), I took on another project, this time in the public sector, as an Automated Test Developer.
                                        Again, roles quickly evolved as I found myself doing DevOps and UI development after no longer than a year on the project, and also working closely with the API team.</p>
                                    <p>In early 2022, I was excited to pick up additional programming experience by joining Data Directions, a Richmond-based IT consulting company, taking on multiple projects in an array of roles including automated test developer as well as lead developer.</p>
                                    <figure className="two-two">
                                        <img src={require('../assets/core.jpeg')} alt="Core Consulting" onLoad={this.props.loadedImage} className="no-border-radius" />
                                        <figcaption>Core Consulting</figcaption>
                                    </figure>
                                    <figure className="two-two">
                                        <img src={require('../assets/data-directions.jpeg')} alt="Data Directions" onLoad={this.props.loadedImage} className="no-border-radius" />
                                        <figcaption>Data Directions</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div >
                </div>
                <br />
            </div>
        );
    }
}
