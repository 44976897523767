import React, { Component } from 'react';
import { Container, Navbar } from 'reactstrap';
import './NavMenu.css';

import * as RecommendMeIcon from '../assets/recommend-me.ico';
import * as DroidleIcon from '../assets/droidle.ico';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow hidden" light>
                    <Container className="navbar-container">
                        <div className="nav-item" style={{ maxWidth: '200px' }}>
                            <figure className="nav-image">
                                <img src={require('../assets/cameron-grand-teton-2.jpeg')} alt="Grand Teton, Wyoming" onLoad={this.props.loadedImage} />
                            </figure>
                            <div className="d-sm-inline-flex flex-sm-row-reverse">
                                <a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/cameron-jones-40b034142" className="text-light navbar-brand"><img src={require('../assets/linkedin.png')} alt="LinkedIn" height="30" width="30" /><span className="align-bottom">&nbsp;&nbsp;Cameron Jones</span></a>
                            </div>
                        </div>
                        <div className="d-none">
                            <iframe className="soundcloud" width="100%" height="300" scrolling="no" frameBorder="no" allow="autoplay" title="Instrumentals Playlist" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1402989340&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false"></iframe>
                        </div>
                        <div className="nav-links hidden">
                            <a href="#" className="item active" onClick={() => this.props.handleInPageLink("home")}>HOME</a>
                            <a href="#" className="item" style={{ letterSpacing: '6px' }} onClick={() => this.props.handleInPageLink("about")}>ABOUT ME</a>
                            <a href="#" className="item" onClick={() => this.props.handleInPageLink("resume")}>RÉSUMÉ</a>
                            <a href="#" className="item" onClick={() => this.props.handleInPageLink("photos")}>PHOTOS</a>
                        </div>
                        <div className="nav-item">
                            <div className="icons large">
                                <div style={{ flexBasis: '100%' }}>
                                    <figure className="one-one" style={{ maxWidth: '100%', fontSize: '80%', margin: '0px' }}>
                                        <img className="no-border-radius" src={require('../assets/sportle-iOS.png')} style={{ borderRadius: '25px', maxWidth: '60px' }} alt="Sportle iOS icon" onLoad={this.props.loadedImage} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936"><figcaption className="link sportle">Sportle (iOS)</figcaption></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle"><figcaption className="link sportle">Sportle (Android)</figcaption></a>
                                        <a target="_blank" rel="noopener noreferrer" href="https://sportlegames.com"><figcaption className="link sportle">SportleGames.com</figcaption></a>
                                    </figure>
                                </div>
                                <div>
                                    <a className="one-one droidle" href="https://droidle.app" target="_blank" rel="noopener noreferrer">
                                        <img src={DroidleIcon} alt="Droidle Icon" onLoad={this.props.loadedImage} />
                                        <figcaption>Droidle.app</figcaption>
                                    </a>
                                </div>
                                <div>
                                    <a className="one-one recommend-me" href="https://recommend-me.io" target="_blank" rel="noopener noreferrer">
                                        <img src={RecommendMeIcon} alt="RecommendMe Icon" onLoad={this.props.loadedImage} />
                                        <figcaption>Recommend-Me.io</figcaption>
                                    </a>
                                </div>
                            </div>
                            <span className="email-info">cmjones997@gmail.com</span>
                        </div>
                    </Container>
                </Navbar>
            </header >
        );
    }
}
