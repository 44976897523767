import React, { Component } from 'react';
import { About } from './About';
import { Resume } from './Resume';
import { Welcome } from './Welcome';
import $ from 'jquery';
import { Quote } from './parts/Quote';
import { Settings } from './parts/Settings';
import { quotes } from '../helpers/quotes';
import { PageNav } from './parts/PageNav';
import { SpinOverlay } from './parts/SpinOverlay';
import { Photos } from './Photos';
import MessageModal from './parts/MessageModal';
import { AboutRenovated } from './About_Renovated';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            quoteOptions: quotes,
            quote1: { quote: '', by: '', from: '', fromType: '', link: '', numIframe: -1, isLoading: true },
            quote2: { quote: '', by: '', from: '', fromType: '', link: '', numIframe: -1, isLoading: true },
            quote3: { quote: '', by: '', from: '', fromType: '', link: '', numIframe: -1, isLoading: true },
        };

        this.displayNextPageArrow = this.displayNextPageArrow.bind(this)
        this.displayPrevPageArrow = this.displayPrevPageArrow.bind(this)
        this.getQuote = this.getQuote.bind(this)
        this.handleIframeLoad = this.handleIframeLoad.bind(this);
        this.toggleMessageModal = this.toggleMessageModal.bind(this)
    }


    componentDidMount() {
        const welcomeBackgroundSrc = $(".home-wrapper").css('background-image');
        const welcomeBackgroundUrl = welcomeBackgroundSrc.match(/\((.*?)\)/)[1].replace(/('|")/g, '');
        let img0 = new Image();
        img0.onload = () => {
            this.props.setWelcomeBackgroundImageLoaded();
        }
        img0.src = welcomeBackgroundUrl;
        if (img0.complete) img0.onload();

        const resumeBackgroundSrc = $(".resume-wrapper").css('background-image');
        const resumeBackgroundUrl = resumeBackgroundSrc.match(/\((.*?)\)/)[1].replace(/('|")/g, '');
        let img2 = new Image();
        img2.onload = () => {
            this.props.setResumeBackgroundImageLoaded();
        }
        img2.src = resumeBackgroundUrl;
        if (img2.complete) img2.onload();

        const delay = ms => new Promise(res => setTimeout(res, ms));
        delay(500).then(() => {
            this.getQuote("quote1", true).then(async () => {
                await delay(200);
                this.getQuote("quote2").then(async () => {
                    await delay(200);
                    this.getQuote("quote3").then(async () => { })
                })
            })
        })

        const self = this;
        const windowHeight = $(window).height();
        let initialRun = true;
        let prevPageTop;
        const lateRaisePtPx = 20;
        $(document).on("scroll", function () {
            const pageTop = $(document).scrollTop();
            const pageBottom = pageTop + windowHeight;
            const tags = $(".fade");

            for (let i = 0; i < tags.length; i++) {
                const tag = tags[i];

                let offsetTop = $(tag).offset().top;
                const client = tag.getBoundingClientRect();
                let percentPageWhenToRaise = client.height < 75 ? .05 : .15;
                if (windowHeight >= 900) { percentPageWhenToRaise *= 1.75 }
                const notScrolledPast = client.bottom > (percentPageWhenToRaise * windowHeight)
                if ((offsetTop + (tag.classList.contains('late') ? lateRaisePtPx : 0)) < pageBottom && notScrolledPast) {
                    if (!tag.classList.contains('up') || pageTop < prevPageTop) {
                        $(tag).addClass(`visible`);
                        $(tag).removeClass(`up`);
                    }
                } else if (!tag.classList.contains('page-header-wrapper') && !tag.classList.contains('section-header-wrapper') && !tag.classList.contains('download-button')) { //keep headers where they are unless they are below the page bottom
                    if (!tag.classList.contains('do-slide') && offsetTop !== 0 && offsetTop < pageBottom && !notScrolledPast && !tag.classList.contains('up')) {
                        $(tag).addClass("up");
                    }
                    $(tag).removeClass("visible");
                } else if (offsetTop > pageBottom || (!tag.classList.contains('section-header-wrapper') && offsetTop + lateRaisePtPx > pageBottom)) {
                    $(tag).removeClass("visible");
                }
            }
            prevPageTop = pageTop;
            if (self.props.imagesShown) {
                self.getCurrentPage();

                if (initialRun) {
                    //rm scroll helpful messages
                    const scrollArrows = document.getElementsByClassName('arrows')[0];
                    const scrollMessage = document.getElementsByClassName('scroll-aid')[0];
                    const navLinks = document.getElementsByClassName('nav-links')[0];
                    navLinks.classList.remove('hidden')
                    navLinks.classList.add('fade-in')
                    if (scrollArrows) { scrollArrows.classList.add('fade-out') }
                    if (scrollMessage) { scrollMessage.classList.add('fade-out') }
                    if (scrollArrows || scrollMessage) {
                        const pages = $(".page");
                        let quotes = $(".quote.do-slide");
                        const smallNav = $(".small-nav");
                        [...pages].concat([...quotes]).concat([...smallNav]).filter(it => !it.classList || (!it.classList.contains('home-page') && !it.classList.contains('slide-up'))).forEach(page => page.classList ? page.classList.add('slide-up') : page.classList = 'slide-up')
                        //add quotes
                        quotes = $(".quote.d-none");
                        if (quotes) { [...quotes].forEach(quote => quote.classList.remove('d-none')) }
                    }
                    initialRun = false;
                }
            }

            //show/hide nav arrows
            const nextPage = document.getElementsByClassName('next-page')[0]
            if (self.props.imagesShown && self.displayNextPageArrow()) {
                const messageButton = document.getElementById("message-button");
                messageButton.classList.remove('hidden')
                messageButton.classList.add('fade-in')
                nextPage.classList.remove('d-none')
            } else if (nextPage.classList) {
                nextPage.classList.add('d-none')
            } else {
                nextPage.classList = 'd-none';
            }
            const prevPage = document.getElementsByClassName('prev-page')[0]
            if (self.props.imagesShown && self.displayPrevPageArrow()) {
                prevPage.classList.remove('d-none')
            } else {
                prevPage.classList.add('d-none')
            }
        });

        //wait for images to load
        let allImgs = document.getElementsByClassName('carousel-wrapper')[0].getElementsByTagName("img");
        allImgs = [...allImgs].filter(img => !img.classList.contains("ignore-load"))
        //[...allImgs].concat(document.getElementsByClassName('carousel-wrapper')[0].getElementsByClassName("video"));
        for (let i = 1; i <= allImgs.length - 1; i++) { // length - 1 because we already start with one false in state
            this.props.imagesLoaded.push(false)
        }
    }

    getCurrentPage() {
        const pages = document.getElementsByClassName('section-grouping');
        const pageTop = $(document).scrollTop();
        const existing = [...pages].filter(it => $(it).offset().top < pageTop + (.1 * $(window).height()))
        let current = existing.length - 1
        if (current === -1) { current = 0 }
        const links = document.getElementsByClassName('nav-links')[0].getElementsByClassName('item');
        [...links].map((item, idx) => {
            if (idx !== current) {
                item.classList.remove('active')
            } else {
                item.classList.add('active')
            }
        })
    }

    displayPrevPageArrow() {
        const pages = document.getElementsByClassName('section-grouping');
        const height = $(window).height();
        const pageTop = $(document).scrollTop();

        return Boolean([...pages].find(it => $(it).offset().top < pageTop - (.3 * height)))
    }

    displayNextPageArrow() {
        const pages = document.getElementsByClassName('section-grouping');
        const pageTop = $(document).scrollTop();
        const height = $(window).height()
        const pageBottom = pageTop + height;

        return Boolean([...pages].find(it => $(it).offset().top >= pageBottom - (.3 * height)))
    }

    toggleMessageModal() {
        this.setState({ messageModal: !this.state.messageModal })
    }

    async getQuote(quoteState, short) {
        let iterativeQuoteState = { ...this.state[quoteState] };
        iterativeQuoteState.isLoading = true;
        iterativeQuoteState.link = '';
        this.setState({ [quoteState]: iterativeQuoteState }, () => {
            let quotes = [...this.state.quoteOptions]
            let usedQuotes = [this.state.quote1, this.state.quote2, this.state.quote3].filter(it => it)
            if (usedQuotes.length) {
                //only pick from authors that haven't been used yet
                quotes = quotes.filter(quote => !usedQuotes.map(it => it.by).includes(quote.by))
                //if there isn't at least one song quote, make it a 20% chance that this one is
                if (!short && !usedQuotes.find(it => it.link) && Math.random() < 0.2) {
                    const quotesWithLinks = quotes.filter(quote => quote.link);
                    if (quotesWithLinks.length) { quotes = quotesWithLinks; }
                }
                //if there are now no quotes to choose from, re-do the search and just make sure it doesn't pick a quote that is already used
                if (!quotes.length) {
                    quotes = [...this.state.quoteOptions].filter(quote => !usedQuotes.map(it => it.quote).includes(quote.quote))
                }
            }
            if (short) { quotes = quotes.filter(it => it.quote.length < 160 && !it.link); }

            const quoteIndex = Math.floor(Math.random() * (quotes.length - 1 - 0 + 1) + 0)
            const quote = quotes[quoteIndex]
            iterativeQuoteState = quote;
            if (iterativeQuoteState.link) { iterativeQuoteState.isLoading = true; } // still - wait for iframe onload to set isLoading = false
            this.setState({ [quoteState]: iterativeQuoteState })
        })
    }

    handleIframeLoad(e, numIframe) {
        let quoteState = [this.state.quote1, this.state.quote2, this.state.quote3].find(it => it.numIframe === numIframe);
        if (quoteState) { quoteState.isLoading = false }
        this.setState({ quoteState })
        e.parentElement.classList.remove('d-none')
    }

    render() {
        const imagesAreLoaded = this.props.imagesLoaded.indexOf(false) === -1

        return (
            <React.Fragment>
                <div className="carousel-wrapper" style={{ overflow: !imagesAreLoaded ? 'hidden' : '', position: !imagesAreLoaded ? 'fixed' : '' }}>
                    <MessageModal open={this.state.messageModal} toggleMessageModal={this.toggleMessageModal} />
                    <ul className="slider">
                        {(!imagesAreLoaded || !this.props.welcomeBackgroundImageLoaded || !this.props.resumeBackgroundImageLoaded) && <SpinOverlay pacman />}
                        {/*<Settings />*/}
                        <div id="message-button" className='hidden'>
                            <button className="btn btn-sm btn-info send-msg-btn" onClick={this.toggleMessageModal}>Send A Message</button>
                        </div>
                        <Welcome loadedImage={this.props.loadedImage} imagesAreLoaded={imagesAreLoaded} isMobile={this.props.isMobile} />
                        <Quote handleIframeLoad={this.handleIframeLoad} getQuote={this.getQuote} quote={this.state.quote1} box={false} slideUp={true} classes={'no-top first'} index={1} state={'quote1'} />
                        <div className={`small-nav btn-group fade hidden${this.props.isFacebook ? ' d-none' : ''}`}>
                            <button type="button" className="btn btn-info" onClick={() => this.props.handleInPageLink("about")}>ABOUT ME</button>
                            <button type="button" className="btn btn-info" onClick={() => this.props.handleInPageLink("resume")}>RÉSUMÉ</button>
                            <button type="button" className="btn btn-info" onClick={() => this.props.handleInPageLink("photos")}>PHOTOS</button>
                        </div>
                        <AboutRenovated loadedImage={this.props.loadedImage} />
                        <Quote handleIframeLoad={this.handleIframeLoad} getQuote={this.getQuote} quote={this.state.quote2} box={false} slideUp={true} classes={'two'} index={2} state={'quote2'} />
                        <Resume loadedImage={this.props.loadedImage} />
                        <Quote handleIframeLoad={this.handleIframeLoad} getQuote={this.getQuote} quote={this.state.quote3} box={false} slideUp={true} classes={'three'} index={3} state={'quote3'} />
                        <Photos loadedImage={this.props.loadedImage} />
                    </ul>
                    <PageNav />
                </div>
            </React.Fragment>
        );
    }
}
