import React, { Component } from 'react';
import $ from 'jquery';

export class PageNav extends Component {
    static displayName = PageNav.name;

    constructor(props) {
        super(props)
        this.state = {}

        this.nextPage = this.nextPage.bind(this)
        this.prevPage = this.prevPage.bind(this)
    }

    prevPage() {
        const pages = document.getElementsByClassName('section-grouping');
        const height = $(window).height();
        const pageTop = $(document).scrollTop();

        const previousPages = [...pages].filter(it => $(it).offset().top < pageTop - (.3 * height))
        if (previousPages) {
            const previousPage = previousPages[previousPages.length - 1]
            if (previousPage) {
                const scrollTo = $(previousPage).offset().top - ($(window).width() > 900 ? 0 : 55);
                const scrollTime = pageTop - scrollTo > 3000 ? 1500 : 800
                if (previousPage.classList.contains('home-page')) {
                    $('html, body').animate({
                        scrollTop: 0
                    }, scrollTime)
                    return;
                }
                $('html, body').animate({
                    scrollTop: scrollTo
                }, scrollTime)
                return
            }
        }
    }

    nextPage() {
        const pages = document.getElementsByClassName('section-grouping');
        const pageTop = $(document).scrollTop();
        const height = $(window).height()
        const pageBottom = pageTop + height;

        const scrollToPage = [...pages].find(it => $(it).offset().top >= pageBottom - (.3 * height))
        if (scrollToPage) {
            const scrollTo = $(scrollToPage).offset().top
            const scrollTime = scrollTo - pageBottom > 3000 ? 1500 : 800
            $('html, body').animate({
                scrollTop: scrollTo
            }, scrollTime)
            return
        }
    }

    render() {
        return (
            <div className="change-page">
                <div className="prev-page d-none">
                    <div className="round" onClick={this.prevPage}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div className="next-page d-none">
                    <div className="round" onClick={this.nextPage}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                </div>
            </div>
        )
    }
}