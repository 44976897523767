import React, { Component } from 'react';
import $ from 'jquery';

export class AboutRenovated extends Component {
    static displayName = AboutRenovated.name;

    constructor(props) {
        super(props)
        this.state = {
        }

        this.handleInPageLink = this.handleInPageLink.bind(this);
    }

    handleInPageLink(id) {
        const el = document.getElementById(id)
        const pageTop = $(document).scrollTop();
        const pageBottom = pageTop + $(window).height();

        const scrollTo = $(el).offset().top
        const scrollTime = scrollTo - pageBottom > 3000 ? 1500 : 800
        $('html, body').animate({
            scrollTop: scrollTo
        }, scrollTime)
    }

    render() {
        return (
            <>
                <div id="about" className="section-container heading page d-none fade late section-grouping">
                    <div className="section-wrapper fade late" />
                    <div className="section">
                        <div className="section-header-wrapper">
                            <span className="app section-header fade">PERSONAL/SIDE WORK</span>
                        </div>
                    </div>
                </div>
                <div className="section-container sportle section page d-none fade">
                    <div className="section-wrapper fade late" />
                    <div className="section fade">
                        <div className="section-header-wrapper fade late">
                            <span className="app sportle">Sportle</span>
                            <span className="sub-header">Available for the <a target="_blank" rel="noopener noreferrer" href="https://sportlegames.com">Web</a>, <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936">iOS</a>, & <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">Android</a></span>
                        </div>
                        <div className="list fade late">
                            <li className="list-card images">
                                <div>
                                    <span>Users have 4-6 attempts (depending on the sport) to guess a mystery athelete from any of their favorite teams & leagues, with a new player being chosen every day.</span>
                                </div>
                                <div className="image-container">
                                    <img className="no-border-radius" src={require('../assets/Sportle-Ravens.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={this.props.loadedImage} />
                                    <img className="no-border-radius" src={require('../assets/Sportle-Lamar.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={this.props.loadedImage} />
                                </div>
                                <span className="icon">1/4</span>
                            </li>
                            <li className="list-card images">
                                <div>
                                    <span>There is also an "All Sports" mode that challenges users who believe they are knowledgeable fans of multiple sports, as this game mode chooses a new mystery player daily from ANY league!</span>
                                </div>
                                <div className="image-container">
                                    <img className="no-border-radius" src={require('../assets/Sportle-All-Sports.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={this.props.loadedImage} />
                                    <img className="no-border-radius" src={require('../assets/Sportle-LeBron.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={this.props.loadedImage} />
                                </div>
                                <span className="icon">2/4</span>
                            </li>
                            <li className="list-card images">
                                <div>
                                    <span>Users can compete with each other using the daily and all-time leaderboards for each league & team.</span>
                                </div>
                                <div className="image-container">
                                    <img className="no-border-radius" src={require('../assets/Sportle-Leaderboard.png')} style={{ borderRadius: '25px' }} alt="Sportle" onLoad={this.props.loadedImage} />
                                </div>
                                <span className="icon">3/4</span>
                            </li>
                            <li className="list-card images">
                                <div>
                                    <span>UI is built using ReactJS for the Web and Flutter for iOS and Android. APIs are built using C# / .NET Core. Hosted on Azure.</span>
                                </div>
                                <div className="image-container rows">
                                    <img className="no-border-radius" src={require('../assets/flutter.png')} style={{ borderRadius: '25px' }} alt="Flutter" onLoad={this.props.loadedImage} />
                                    <img className="no-border-radius" src={require('../assets/react.png')} style={{ borderRadius: '25px', padding: '0px 60px' }} alt="React" onLoad={this.props.loadedImage} />
                                </div>
                                <span className="icon">4/4</span>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="section-container recommend-me section page d-none fade">
                    <div className="section-wrapper fade late" />
                    <div className="section fade">
                        <div className="section-header-wrapper fade late">
                            <a className="app" target="_blank" rel="noopener noreferrer" href="https://recommend-me.io">Recommend-Me.io</a>
                            <span className="sub-header smol">The very first web application I built, Recommend-Me.io is a free service that provides recommendations for music, tv shows, movies, and podcasts.</span>
                        </div>
                        <div className="list fade late">
                            <li className="list-card"><span>Entering a variety of inputs, such as mood, favorite music artists, favorite actors & actresses, etc., this application provides a quick and easy way to get helpful recommendations.</span><span className="icon">1/3</span></li>
                            <li className="list-card"><span>Integrated with Spotify, users are able to quickly and easily create playlists as well as add recommendations to them.</span><span className="icon">2/3</span></li>
                            <li className="list-card"><span>UI is built using ReactJS. APIs are built using C# / .NET Core. Hosted on Azure.</span><span className="icon">3/3</span></li>
                        </div>
                        <div className="images fade late">
                            <img className="no-border-radius fade" src={require('../assets/Recommend-Me-Mood.png')} alt="Recommend Me" onLoad={this.props.loadedImage} />
                        </div>
                    </div>
                </div>
                <div className="section-container droidle section page d-none fade">
                    <div className="section-wrapper fade late" />
                    <div className="section fade">
                        <div className="section-header-wrapper fade late">
                            <a className="app" target="_blank" rel="noopener noreferrer" href="https://droidle.app">Droidle.app</a>
                            <span className="sub-header smol">Users compete against a bot in a series of challenging word games at a user-chosen level of difficulty.</span>
                        </div>
                        <div className="images fade late">
                            <img className="no-border-radius smol fade" src={require('../assets/Droidle-Home-2.png')} style={{ borderRadius: '25px' }} alt="Droidle" onLoad={this.props.loadedImage} />
                        </div>
                        <div className="list fade late">
                            <li className="list-card images">
                                <div>
                                    <span>Users may compete head-to-head against a Droidle, trying to be the first to solve a system-generated mystery word.</span>
                                </div>
                                <div className="image-container">
                                    <img className="no-border-radius smol" src={require('../assets/Droidle-One-V-One.png')} alt="Droidle" onLoad={this.props.loadedImage} />
                                </div>
                                <span className="icon">1/2</span>
                            </li>
                            <li className="list-card images">
                                <div>
                                    <span>Alternatively, users may try to outsmart a Droidle by thinking of a word that they believe the Droidle will not successfully guess.</span>
                                </div>
                                <div className="image-container">
                                    <img className="no-border-radius smol" src={require('../assets/Droidle-Outsmart-2.png')} style={{ borderRadius: '25px' }} alt="Droidle" onLoad={this.props.loadedImage} />
                                </div>
                                <span className="icon">2/2</span>
                            </li>
                        </div>
                    </div>
                </div>
                <div className="section-container heading page d-none fade late">
                    <div className="section-wrapper fade late" />
                    <div className="section">
                        <div className="section-header-wrapper fade">
                            <span className="app section-header">HOBBIES/PASSIONS</span>
                        </div>
                    </div>
                </div>
                <div className="section-container nature section page d-none fade">
                    <div className="section-wrapper fade late" />
                    <div className="section fade">
                        <div className="section-header-wrapper fade late">
                            <span className="app">Nature/Travel</span>
                        </div>
                        <div className="fade late">
                            <p>I've always been fascinated by "the great outdoors" and seeing what the massive, broad world has to offer, but I didn't do a ton of exploring until I reached college.
                                It was only then that I started to pursue my passion for hiking, international travel, etc.</p><p>Once I began, though, I couldn't get enough — since graduating, I have continued to pursue this passion, most notably by traveling
                                    around the United States in 2021 by car with my dog Murph, a Border Collie rescue from the Bahamas. I then traveled with Murph again in 2022 through the Northeastern United States and Canada. For more photos & videos of these journies, please see the <a href="#" className="link-primary" onClick={() => this.handleInPageLink("photos")}>Photos</a> section.</p>
                            <div className="nature-images">
                                <div>
                                    <figure className="three-three fade">
                                        <img src={require('../assets/matthews-park-colorado.jpeg')} alt="Colorado" onLoad={this.props.loadedImage} />
                                        <figcaption>Colorado</figcaption>
                                    </figure>
                                </div>
                                <div>
                                    <figure className="three-three fade">
                                        <img src={require('../assets/lake-tahoe.jpeg')} alt="Lake Tahoe, California" onLoad={this.props.loadedImage} />
                                        <figcaption>Lake Tahoe, California</figcaption>
                                    </figure>
                                </div>
                                <div>
                                    <figure className="three-three fade">
                                        <img src={require('../assets/cameron-diamond-lake.jpeg')} alt="Diamond Lake, Oregon" onLoad={this.props.loadedImage} />
                                        <figcaption>Diamond Lake, Oregon</figcaption>
                                    </figure>
                                </div>
                                <div>
                                    <figure className="three-three fade" >
                                        <img src={require('../assets/cameron-grand-teton-1.jpeg')} alt="Grand Teton, Wyoming" onLoad={this.props.loadedImage} />
                                        <figcaption>Grand Teton, Wyoming</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-container reading section page d-none fade">
                    <div className="section-wrapper fade late" />
                    <div className="section fade">
                        <div className="section-header-wrapper fade late">
                            <span className="app">Reading</span>
                        </div>
                        <div className="book-genres-container fade late">
                            <p>An easy way for me to wind down and relax is to pick up a book. Typical genres for me include:</p>
                            <div className="book-genres">
                                <div className="book-genre">
                                    <span className="genre-header">neuroscience/psychology</span>
                                    <ul>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/25776132-the-brain">The Brain: The Story of You</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/en/book/show/9827912-incognito">Incognito: The Secret Lives of the Brain</a></em></li>
                                    </ul>
                                </div>
                                <div className="book-genre">
                                    <span className="genre-header">philosophy</span>
                                    <ul>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/30659.Meditations">Meditations</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/4069.Man_s_Search_for_Meaning">Man's Search for Meaning</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/6596.The_Four_Agreements">The Four Agreements</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/6708.The_Power_of_Now">The Power of Now</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/2547.The_Prophet">The Prophet</a></em></li>
                                    </ul>
                                </div>
                                <div className="book-genre">
                                    <span className="genre-header">fiction</span>
                                    <ul>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/18144590-the-alchemist">The Alchemist</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/3636.The_Giver">The Giver</a></em></li>
                                        <li><em><a rel="noopener noreferrer" target="_blank" href="https://www.goodreads.com/book/show/43708884-the-boy-the-mole-the-fox-and-the-horse">The Boy, the Mole, the Fox, and the Horse</a></em></li>
                                    </ul>
                                    <small>(yes, that last one is a children's book, and it's incredible)</small>
                                </div>
                            </div>
                            <div>
                                <figure className="three-onefive">
                                    <img src={require('../assets/the_brain.jpg')} alt="The Brain" className="no-border-radius" onLoad={this.props.loadedImage} />
                                    <figcaption><em>The Brain: The Story of You</em></figcaption>
                                </figure>
                                <figure className="three-onefive">
                                    <img src={require('../assets/the_giver.jpg')} alt="The Giver" className="no-border-radius" onLoad={this.props.loadedImage} />
                                    <figcaption><em>The Giver</em></figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-container others section page d-none fade">
                    <div className="section-wrapper fade late" />
                    <div className="section fade">
                        <div className="section-header-wrapper fade late">
                            <span className="app">Others</span>
                        </div>
                        <div className="others-container fade late">
                            <div className="group">
                                <h3>Sports</h3>
                                <div>
                                    <p>A highly active kid, I began playing soccer at around six years old and still do so today. I have also spent time as a volunteer assistant with the Mills Godwin High School Boys Soccer program.
                                        As far as professional sports go, I'm a massive Baltimore Ravens fan, and enjoy watching the NBA and Premier League without as much significant interest in any one specific team.</p>
                                    <figure className="three-threefive">
                                        <img src={require('../assets/states.jpeg')} alt="Virginia 5A State Champions (2015)" onLoad={this.props.loadedImage} />
                                        <figcaption>Virginia 5A State Champions (2015)</figcaption>
                                    </figure>
                                </div>
                            </div>
                            <div className="group">
                                <h3>Animals</h3>
                                <div>
                                    <p>Not that it wasn't already obvious given all the pictures of my dog, but I am indeed an animal enthusiast. Aside from growing up with bunnies, hamsters, guinea pigs, birds, and more,
                                        I also spent a brief period of time before COVID-19 volunteering at a local nonprofit, no-kill humane society.</p>
                                    <figure className="three-three">
                                        <img src={require('../assets/cameron-murph.jpeg')} alt="Murphy" onLoad={this.props.loadedImage} />
                                        <br /><figcaption>Murphy</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
