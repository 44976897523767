import React, { Component } from 'react';

export class Quote extends Component {
    static displayName = Quote.name;

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { box, slideUp, classes, quote, index, state } = this.props;

        return (
            <div className={`quote fade d-none${box ? ' box' : ''}${slideUp ? ' do-slide' : ''}${classes ? ` ${classes}` : ''}`}>
                {quote &&
                    <React.Fragment>
                        <div className="quote-title">
                            <span>Random Quote #{index}</span>
                            {!quote.isLoading &&
                                <button className={`btn btn-sm ${box ? 'btn-info' : 'btn-outline-info'}`} onClick={() => this.props.getQuote(state, !box)}>New Quote</button>
                            }
                        </div>
                        {quote.isLoading ?
                            <div>
                                <div className="d-flex justify-content-center center-spinner" style={{ position: 'inherit' }}>
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <p className="quote-msg">"{quote.quote}"</p>
                                {quote.by &&
                                    <p className="quote-author"><span>- {quote.by}</span>
                                        {quote.from && (quote.fromType === "book" || quote.fromType === "movie") && <React.Fragment><span>, </span><span className="font-italic">{quote.from}</span></React.Fragment>}
                                        {quote.from && (quote.fromType === "song" || quote.fromType === "poem") && <span>, "{quote.from}"</span>}
                                    </p>
                                }
                            </React.Fragment>
                        }
                        {quote.link &&
                            <p className="spotify-iframe-container d-none">
                                <iframe key={quote.numIframe} src={quote.link} onLoad={(e) => this.props.handleIframeLoad(e.target, quote.numIframe)} title={quote.from + ' - ' + quote.by} className="spotify-iframe" height="80" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
                            </p>
                        }
                    </React.Fragment>
                }
            </div>
        )
    }
}