export const quotes = [
    {
        quote: "The way you get meaning into your life is to devote yourself to loving others, devote yourself to your community around you, and devote yourself to creating something that gives you purpose and meaning.",
        by: "Mitch Albom",
        from: "Tuesdays with Morrie",
        fromType: "book"
    },
    {
        quote: "Don't let go too soon, but don't hold on too long.",
        by: "Mitch Albom",
        from: "Tuesdays with Morrie",
        fromType: "book"
    },
    {
        quote: "'Everyone knows they're going to die,' he said again, 'but nobody believes it. If we did, we would do things differently.'",
        by: "Mitch Albom",
        from: "Tuesdays with Morrie",
        fromType: "book"
    },
    {
        quote: "I thought about all the people I knew who spent many of their waking hours feeling sorry for themselves. How useful it would be to put a daily limit on self-pity. Just a few tearful minutes, then on with the day.",
        by: "Mitch Albom",
        from: "Tuesdays with Morrie",
        fromType: "book"
    },
    {
        quote: "And, when you want something, all the universe conspires in helping you to achieve it.",
        by: "Paulo Coelho",
        from: "The Alchemist",
        fromType: "book"
    },
    {
        quote: "It's the possibility of having a dream come true that makes life interesting.",
        by: "Paulo Coelho",
        from: "The Alchemist",
        fromType: "book"
    },
    {
        quote: "The simple things are also the most extraordinary things, and only the wise can see them.",
        by: "Paulo Coelho",
        from: "The Alchemist",
        fromType: "book"
    },
    {
        quote: "People are capable, at any time in their lives, of doing what they dream of.",
        by: "Paulo Coelho",
        from: "The Alchemist",
        fromType: "book"
    },
    {
        quote: "'When have you been at your strongest?' asked the boy.\n'When I have dared to show my weakness,' said the horse. 'Asking for help isn't giving up. It's refusing to give up.'",
        by: "Charlie Mackesy",
        from: "The Boy, the Mole, the Fox and the Horse",
        fromType: "book"
    },
    {
        quote: "'Is your glass half empty or half full?' asked the mole.\n'I think I'm grateful to have a glass,' said the boy.",
        by: "Charlie Mackesy",
        from: "The Boy, the Mole, the Fox and the Horse",
        fromType: "book"
    },
    {
        quote: "One of our greatest freedoms is how we react to things.",
        by: "Charlie Mackesy",
        from: "The Boy, the Mole, the Fox and the Horse",
        fromType: "book"
    },
    {
        quote: "'The greatest illusion,' said the mole, 'is that life should be perfect.'",
        by: "Charlie Mackesy",
        from: "The Boy, the Mole, the Fox and the Horse",
        fromType: "book"
    },
    {
        quote: "Imagine how we would be if we were less afraid.",
        by: "Charlie Mackesy",
        from: "The Boy, the Mole, the Fox and the Horse",
        fromType: "book"
    },
    {
        quote: "Most of the old moles I know wish they had listened less to their fears and more to their dreams.",
        by: "Charlie Mackesy",
        from: "The Boy, the Mole, the Fox and the Horse",
        fromType: "book"
    },
    {
        quote: "The timeless in you is aware of life's timelessness. And knows that yesterday is but today's memory and tomorrow is today's dream.",
        by: "Khalil Gibran",
        from: "The Prophet",
        fromType: "book"
    },
    {
        quote: "And forget not that the earth delights to feel your bare feet and the winds long to play with your hair.",
        by: "Khalil Gibran",
        from: "The Prophet",
        fromType: "book"
    },
    {
        quote: "It is not uncommon for people to spend their whole life waiting to start living.",
        by: "Eckhart Tolle",
        from: "The Power of Now",
        fromType: "book"
    },
    {
        quote: "As soon as you honor the present moment, all unhappiness and struggle dissolve, and life begins to flow with joy and ease. When you act out the present-moment awareness, whatever you do becomes imbued with a sense of quality, care, and love — even the most simple action.",
        by: "Eckhart Tolle",
        from: "The Power of Now",
        fromType: "book"
    },
    {
        quote: "What a caterpillar calls the end of the world we call a butterfly.",
        by: "Eckhart Tolle",
        from: "The Power of Now",
        fromType: "book"
    },
    {
        quote: "Nothing has happened in the past; it happened in the Now. Nothing will ever happen in the future; it will happen in the Now.",
        by: "Eckhart Tolle",
        from: "The Power of Now",
        fromType: "book"
    },
    {
        quote: "I've had a lot of worries in my life, most of which never happened.",
        by: "Mark Twain",
        from: "",
        fromType: ""
    },
    {
        quote: "One day, in retrospect, the year of struggle will strike you as the most beautiful.",
        by: "Sigmund Freud",
        from: "",
        fromType: ""
    },
    {
        quote: "In a world of constant change and streaming technology, I find solace in the forest where a tree remains a tree.",
        by: "Angie Weiland-Crosby",
        from: "",
        fromType: ""
    },
    {
        quote: "Look deep into nature, and then you will understand everything better.",
        by: "Albert Einstein",
        from: "",
        fromType: ""
    },
    {
        quote: "There is a pleasure in the pathless woods.",
        by: "F. Scott Fitzgerald",
        from: "",
        fromType: ""
    },
    {
        quote: "There is some good in this world, and it’s worth fighting for.",
        by: "J.R.R. Tolkien",
        from: "The Two Towers",
        fromType: "book"
    },
    {
        quote: "I wanted you to see what real courage is, instead of getting the idea that courage is a man with a gun in his hand. It’s when you know you’re licked before you begin but you begin anyway and you see it through no matter what. You rarely win, but sometimes you do.",
        by: "Harper Lee",
        from: "To Kill a Mockingbird",
        fromType: "book"
    },
    {
        quote: "A man, after he has brushed off the dust and chips of his life, will have left only the hard, clean questions: Was it good or was it evil? Have I done well — or ill?",
        by: "John Steinbeck",
        from: "East of Eden",
        fromType: "book"
    },
    {
        quote: "I am not afraid of storms, for I am learning how to sail my ship.",
        by: "Louisa May Alcott",
        from: "Little Women",
        fromType: "book"
    },
    {
        quote: "Make a radical change in your lifestyle and begin to boldly do things which you may previously never have thought of doing, or been too hesitant to attempt.",
        by: "Jon Krakauer",
        from: "Into the Wild",
        fromType: "book"
    },
    {
        quote: "The very basic core of a man's living spirit is his passion for adventure. The joy of life comes from our encounters with new experiences, and hence there is no greater joy than to have an endlessly changing horizon, for each day to have a new and different sun.",
        by: "Jon Krakauer",
        from: "Into the Wild",
        fromType: "book"
    },
    {
        quote: "If you want to get more out of life, you must lose your inclination for monotonous security and adopt a helter-skelter style of life that will at first appear to you to be crazy. But once you become accustomed to such a life you will see its full meaning and its incredible beauty.",
        by: "Jon Krakauer",
        from: "Into the Wild",
        fromType: "book"
    },
    {
        quote: "Your thoughts are incredibly powerful. Choose yours wisely.",
        by: "Joe Dispenza",
        from: "You Are the Placebo",
        fromType: "book"
    },
    {
        quote: "Who you are is defined by what you're willing to struggle for.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "Everything worthwhile in life is won through surmounting the associated negative experience.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "To be happy we need something to solve. Happiness is therefore a form of action.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "This is the most simple and basic component of life: our struggles determine our success.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "Don't just sit there. Do something. The answers will follow.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "We don't always control what happens to us. But we always control how we interpret what happens to us, as well as how we respond.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "Travel is a fantastic self-development tool, because it extricates you from the values of your culture and shows you that another society can live with entirely different values and still function and not hate themselves ... [which] then forces you to reexamine what seems obvious in your own life and to consider that perhaps it's not necessarily the best way to live.",
        by: "Mark Manson",
        from: "The Subtle Art of Not Giving a F*ck",
        fromType: "book"
    },
    {
        quote: "Don't make friends who are comfortable to be with. Make friends who will force you to lever yourself up.",
        by: "Thomas J. Watson",
        from: "",
        fromType: ""
    },
    {
        quote: "Spend your time working on things that are enjoyable yet challenging.",
        by: "Unknown",
        from: "",
        fromType: ""
    },
    {
        quote: "But often, in the world's most crowded streets,\nBut often, in the din of strife,\nThere rises an unspeakable desire\nAfter the knowledge of our buried life",
        by: "Matthew Arnold",
        from: "The Buried Life",
        fromType: "poem"
    },
    {
        quote: "Whatever path you follow, push on till tomorrow. Love all, serve all, and create no sorrow.",
        by: "Trevor Hall",
        from: "Unity",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/4qZKvkXvim77t0DmGaqXwp?utm_source=generator",
        numIframe: 1
    },
    {
        quote: "Ever since I was a young kid, I was always hungry for something. It was this thing inside me that no matter what I did, I couldn't satisfy it.",
        by: "Trevor Hall",
        from: "",
        fromType: ""
    },
    {
        quote: "You don’t think that much about life. You just live it.",
        by: "Luke Combs",
        from: "She Got the Best of Me",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/698eQRku24PIYPQPHItKlA?utm_source=generator",
        numIframe: 2
    },
    {
        quote: "Some days you’re just alive, some days you’re livin’.",
        by: "Dierks Bentley",
        from: "Living",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/54K7AZoGpQklpygKrxZlKN?utm_source=generator",
        numIframe: 3
    },
    {
        quote: "Half your life you struggle, half your life you fly. Half your life making trouble, half your life making it right. One day I'm the exception, most days I'm just like most. Some days I'm headed in the right direction, and some days I ain't even close.",
        by: "Dierks Bentley",
        from: "Burning Man",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/4a1oIihVt6xSnDRpgX387M?utm_source=generator",
        numIframe: 4
    },
    {
        quote: "It just took a little step, a right then a left, then a couple million more who’s countin’? Hell, it’s only a mountain.",
        by: "Dierks Bentley",
        from: "The Mountain",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/0vF1odB4L0mw7GzbAehrBR?utm_source=generator",
        numIframe: 5
    },
    {
        quote: "I believe this world ain't half as bad as it looks. I believe most people are good.",
        by: "Luke Bryan",
        from: "Most People Are Good",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/79LAFAkR1ATb4iIQvZuCL0?utm_source=generator",
        numIframe: 6
    },
    {
        quote: "I know you got mountains to climb, but always stay humble and kind.",
        by: "Tim McGraw",
        from: "Humble and Kind",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/4Pn0JlCUusD2QHjADuOzuV?utm_source=generator",
        numIframe: 7
    },
    {
        quote: "Well, I'm what I am, and I'm what I'm not. And I'm sure happy with what I've got. I live to love and laugh a lot, and that's all I need.",
        by: "Kenny Chesney",
        from: "Never Wanted Nothing More",
        fromType: "song",
        link: "https://open.spotify.com/embed/track/2q49JWdTqa951SdCXZW44g?utm_source=generator",
        numIframe: 8
    },
    {
        quote: `i closed my eyes
                to look inward
                and found a universe
                waiting to be explored`,
        by: "Yung Pueblo",
        from: "Inward",
        fromType: "book",
    },
    {
        quote: `you were born to create
                let it flow`,
        by: "Yung Pueblo",
        from: "Inward",
        fromType: "book",
    },
    {
        quote: `Let us trust ourselves completely to make the choices we must make.`,
        by: "Don Miguel Ruiz",
        from: "The Mastery of Love",
        fromType: "book",
    },
    {
        quote: `The happiest moments in our lives are when we are playing just like children, when we are singing and dancing, when we are exploring and creating just for fun.`,
        by: "Don Miguel Ruiz",
        from: "The Mastery of Love",
        fromType: "book",
    },
    {
        quote: "You came empty handed, and you will leave empty handed.",
        by: "Bhagavad Gita",
        from: "",
        fromType: ""
    },
    {
        quote: "Seek refuge in the attitude of detachment and you will amass the wealth of spiritual awareness. The one who is motivated only by the desire for the fruits of their action, and anxious about the results, is miserable indeed.",
        by: "Bhagavad Gita",
        from: "",
        fromType: ""
    },
    {
        quote: "Work for work’s sake, not for yourself. Act but do not be attached to your actions. Be in the world, but not of it.",
        by: "Bhagavad Gita",
        from: "",
        fromType: ""
    }
]