import React, { Component } from 'react';
import PacmanLoader from "react-spinners/PacmanLoader";

export class SpinOverlay extends Component {
    static displayName = SpinOverlay.name;

    render() {
        return (
            <div className="spin-overlay">
                <div className={`${!this.props.pacman ? 'd-flex justify-content-center ' : ''}center-spinner`}>
                    {this.props.pacman ?
                        <>
                            <p>
                                <PacmanLoader color='yellow' size={25} margin={2} />
                            </p>
                        </>
                        :
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                </div>
                {this.props.pacman && <p className="spinner-text">Loading...</p>}
            </div>
        )
    }
}