import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import $ from 'jquery';

import './stylesheets/custom.css'
import './stylesheets/mobile.css'
import './stylesheets/larger.css'
import './stylesheets/fonts.css'

export default class App extends Component {
    static displayName = App.name;
    constructor(props) {
        super(props);
        this.state = {
            welcomeBackgroundImageLoaded: false,
            resumeBackgroundImageLoaded: false,
            imagesLoaded: [false], //start with one so imagesAreLoaded doesn't evaluate to true at first
            imagesShown: false
        }

        this.loadedImage = this.loadedImage.bind(this);
        this.handleInPageLink = this.handleInPageLink.bind(this);
        this.setWelcomeBackgroundImageLoaded = this.setWelcomeBackgroundImageLoaded.bind(this);
        this.setResumeBackgroundImageLoaded = this.setResumeBackgroundImageLoaded.bind(this);
    }

    setWelcomeBackgroundImageLoaded() {
        this.setState({ welcomeBackgroundImageLoaded: true }, () => { this.loadedImage(true) });
    }

    setResumeBackgroundImageLoaded() {
        this.setState({ resumeBackgroundImageLoaded: true }, () => { this.loadedImage(true) });
    }

    loadedImage(backgroundImage) {
        let imagesLoaded = [...this.state.imagesLoaded]
        let unloadedImage = imagesLoaded.indexOf(false)
        if (backgroundImage !== true && unloadedImage !== -1) {
            imagesLoaded[unloadedImage] = true
        }

        this.setState({ imagesLoaded }, async () => {
            if (this.state.welcomeBackgroundImageLoaded && this.state.resumeBackgroundImageLoaded && this.state.imagesLoaded.indexOf(false) === -1) {
                const delay = ms => new Promise(res => setTimeout(res, ms));
                //add fade-in animations
                let figures = document.getElementById('home').getElementsByTagName('figure');
                figures = [...figures]
                if (window.innerWidth >= 1150) {
                    figures = figures.filter(it => !it.classList.contains('extra-img'))
                }
                const welcomeContainer = document.getElementsByClassName('welcome-message-container')[0]
                const welcomeMessage = document.getElementsByClassName('welcome-message')[0]
                welcomeMessage.classList.remove('hidden')
                welcomeMessage.classList.add('fade-in');
                const welcomeMessage2 = document.getElementsByClassName('welcome-message-two')[0];
                const welcomeBackground = document.getElementsByClassName('home-wrapper')[0];
                const murphy = document.getElementsByClassName('murphy')[0]
                const tags = document.getElementsByClassName('tags welcome');
                const scrollMessage = document.getElementsByClassName('scroll-aid')[0];
                const scrollArrows = document.getElementsByClassName('arrows')[0];
                // const settingsContainer = document.getElementsByClassName("settings-container")[0];
                const navbar = document.getElementsByClassName("navbar")[0];
                const smallNav = document.getElementsByClassName('small-nav')[0];
                await delay(1500);
                welcomeContainer.classList.add('move-over');
                if (window.innerWidth > 900) {
                    await delay(225);
                }
                welcomeBackground.classList.remove('hidden')
                welcomeBackground.classList.add('fade-in');
                await delay(1300);
                welcomeMessage2.classList.remove('hidden')
                welcomeMessage2.classList.add('fade-in');
                [...tags].forEach(tag => {
                    tag.classList.remove('hidden')
                    tag.classList.add('fade-in')
                })
                await delay(1750);
                murphy.classList.remove('hidden')
                murphy.classList.add('fade-in');
                await delay(1750);
                for (let figure of figures) {
                    figure.classList.remove('hidden')
                    figure.classList.add('fade-in')
                    await delay(1000)
                }
                navbar.classList.remove('hidden');
                navbar.classList.add('fade-in');
                smallNav.classList.remove('hidden')
                // settingsContainer.classList.remove('hidden')
                //  settingsContainer.classList.add('fade-in')
                scrollMessage.classList.remove('hidden')
                scrollMessage.classList.add('fade-in')
                scrollArrows.classList.remove('hidden')
                scrollArrows.classList.add('fade-in')
                this.setState({ imagesShown: true })

                const pages = document.getElementsByClassName('page')
                for (let page of pages) {
                    page.classList.remove('d-none')
                }
            }
        })
    }

    handleInPageLink(id) {
        const el = document.getElementById(id)
        const pageTop = $(document).scrollTop();
        const pageBottom = pageTop + $(window).height();

        const scrollTo = $(el).offset().top;
        const scrollTime = scrollTo - pageBottom > 3000 ? 1500 : 800
        $('html, body').animate({
            scrollTop: scrollTo
        }, scrollTime)
    }

    render() {
        const isMobile = (/Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent));
        const isFacebook = (/FBAN/i.test(navigator.userAgent));

        return (
            <Layout loadedImage={this.loadedImage} imagesLoaded={this.state.imagesLoaded} imagesShown={this.state.imagesShown} handleInPageLink={this.handleInPageLink}>
                <Route render={() => <Home loadedImage={this.loadedImage} imagesLoaded={this.state.imagesLoaded} setWelcomeBackgroundImageLoaded={this.setWelcomeBackgroundImageLoaded} setResumeBackgroundImageLoaded={this.setResumeBackgroundImageLoaded} welcomeBackgroundImageLoaded={this.state.welcomeBackgroundImageLoaded} resumeBackgroundImageLoaded={this.state.resumeBackgroundImageLoaded} imagesShown={this.state.imagesShown} isMobile={isMobile} isFacebook={isFacebook} handleInPageLink={this.handleInPageLink} />} />
            </Layout>
        );
    }
}
