import React, { Component } from 'react';

export class Welcome extends Component {
    static displayName = Welcome.name;

    getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;

    }

    render() {
        const showMessageBelowImages = window.innerWidth <= 1150;
        const welcomeMessageExtra = (
            <div className={`${showMessageBelowImages ? 'welcome-message-non-flex' : ''} scroll-aid hidden`}>
                <span className="tags start p">&lt;p&gt;</span>
                <p className="scroll-message">{this.props.isMobile ? 'Swipe' : 'Scroll'} down to learn more about me.</p>
                <span className="tags end p">&lt;/p&gt;</span>
                <svg className="arrows hidden">
                    <path className="a1" d="M0 0 L15 16 L30 0"></path>
                    <path className="a2" d="M0 10 L15 26 L30 10"></path>
                    <path className="a3" d="M0 20 L15 36 L30 20"></path>
                </svg>
            </div>
        )
        return (
            <div id="home">
                <div className="home-wrapper hidden" />
                <div className="overlay" />
                <div className="page home-page section-grouping">
                    <div className="home-flex">
                        <div className="welcome-message-container">
                            <div>
                                <span className="tags start welcome hidden">&lt;h5&gt;</span>
                                <p className={`welcome-message one hidden`}>Hello.</p>
                                <p className="welcome-message welcome-message-two hidden">I'm Cameron, a {this.getAge('1997/08/26')} year old software developer from Richmond, Virginia.</p>
                                <p className="tags end welcome hidden right">&lt;/h5&gt;</p>
                            </div>
                        </div>
                    </div>
                    {welcomeMessageExtra}
                    <div className="murphy hidden">
                        <div class="murphy-arrow">
                            <div class="murphy-line" />
                            <div class="murphy-point" />
                        </div>
                        <span>(And this is Murph -- the one you really care about here)</span>
                    </div>
                </div >
            </div>
        )
    }
}
