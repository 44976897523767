import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { ToastContainer } from 'react-toastify';
import { NavMenu } from './NavMenu';
import "react-toastify/dist/ReactToastify.css";

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div className="large-flex">
                <NavMenu loadedImage={this.props.loadedImage} imagesLoaded={this.props.imagesLoaded} imagesShown={this.props.imagesShown} handleInPageLink={this.props.handleInPageLink}/>
                <Container style={{ display: 'flex'}}>
                    {this.props.children}
                    <ToastContainer style={{ textAlign: "center" }}
                        position="bottom-right"
                        hideProgressBar={false}
                        autoClose={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        draggable={false}
                        rtl={false}
                    />
                </Container>
            </div>
        );
    }
}
